body>iframe {
  pointer-events: none;
}

.re-captcha iframe {
  pointer-events: inherit !important;
}

.check-booking-space {
  padding-top: 200px;
  padding-bottom: 120px;
}

.check-booking {
  background-color: #f8f9fa;
  border: 2px solid #ebedee;
  margin: 8px auto 30px;
  border-radius: 4px;
  width: 95%;
  max-width: 1200px;
  padding: 30px 36px;
}

.check-booking .check-booking-result {
  color: #4579b2;
  font-size: 24px;
  font-weight: 600;
  text-align: left;
  line-height: 1.2;
  margin-bottom: 6px;
}

.check-booking .check-booking-text {
  font-size: 16px;
  margin-bottom: 20px;
}

.check-booking-collect {
  display: table;
  position: relative;
  background-color: #fff;
  width: 100%;
  border-radius: 4px;
  margin-bottom: 8px;
  font-size: 13px;
  line-height: 1.5;
  height: 82px;
  overflow: hidden;
}

.check-booking-collect .check-booking-logo {
  vertical-align: middle;
  text-align: center;
  width: 98px;
  padding: 0 10px;
}

.check-booking-collect .check-booking-logo img {
  width: 100%;
  height: auto;
  vertical-align: middle;
  border-radius: 2px;
}

.check-booking-collect .check-booking-service {
  vertical-align: middle;
  width: 150px;
  padding-right: 10px;
}

.quoteResult>div {
  display: table-cell;
}

.quotePanel {
  background-color: #f8f9fa;
  border: 2px solid #ebedee;
  margin: 8px auto 30px;
  border-radius: 4px;
  width: 95%;
  max-width: 1200px;
  padding: 30px 36px;
}

.quotePanel .resultType {
  color: #4579b2;
  font-size: 24px;
  font-weight: 600;
  text-align: left;
  line-height: 1.2;
  margin-bottom: 6px;
}

.quotePanel .resultText {
  font-size: 16px;
  margin-bottom: 20px;
}

#noResults {
  max-width: 300px;
  margin: 0 auto;
  border: 1px solid red;
  text-align: center;
}

.quoteResult {
  display: table;
  position: relative;
  background-color: #fff;
  width: 100%;
  border-radius: 4px;
  margin-bottom: 8px;
  font-size: 13px;
  line-height: 1.5;
  height: 82px;
  overflow: hidden;
}

.quoteResult>div {
  display: table-cell;
}

.quoteResult span {
  vertical-align: baseline;
  display: inline-block;
}

.quoteResult>div.quoteBreak1 {
  display: none;
}

.quoteResult>div.quoteBreak3 {
  display: none;
}

.quoteResult .quoteLogo {
  vertical-align: middle;
  text-align: center;
  width: 98px;
  padding: 0 10px;
}

.quoteResult .quoteLogo img {
  width: 100%;
  height: auto;
  vertical-align: middle;
  border-radius: 2px;
}

.quoteResult .quoteService {
  vertical-align: middle;
  width: 150px;
  padding-right: 10px;
}

.quoteResult .quoteCollDropImage {
  vertical-align: middle;
  padding-right: 10px;
  width: 33px;
}

.quoteResult .quoteCollDropImage>img {
  vertical-align: middle;
  cursor: help;
}

.quoteResult .quoteCollDropText {
  vertical-align: middle;
  width: 90px;
  padding-right: 10px;
}

.quoteResult .quoteCollDropText>span {
  cursor: help;
}

.quoteResult .quoteDelImage {
  vertical-align: middle;
  padding-right: 10px;
  width: 33px;
}

.quoteResult .quoteDelImage>img {
  vertical-align: middle;
  cursor: help;
}

.quoteResult .quoteDelText {
  vertical-align: middle;
  width: 134px;
  padding-right: 10px;
}

.quoteResult .quoteDelText>span {
  cursor: help;
}

.quoteResult .quoteCoverImage {
  vertical-align: middle;
  padding-right: 10px;
  width: 33px;
}

.quoteResult .quoteCoverImage>img {
  vertical-align: middle;
  cursor: help;
}

.quoteResult .quoteCoverText {
  vertical-align: middle;
  width: 90px;
  padding-right: 10px;
}

.quoteResult .quoteCoverText>span {
  cursor: help;
}

.quoteResult .quotePrinterImage {
  vertical-align: middle;
  padding-right: 10px;
  width: 33px;
}

.quoteResult .quotePrinterImage>img {
  vertical-align: middle;
  cursor: help;
}

.quoteResult .quotePrinterText {
  vertical-align: middle;
  width: 90px;
  padding-right: 10px;
}

.quoteResult .quotePrinterText>span {
  cursor: help;
}

.quoteResult>div.quoteInfoImage {
  vertical-align: middle;
  padding-right: 10px;
  width: 33px;
}

.quoteResult>div.quoteInfoImage>img {
  vertical-align: middle;
  cursor: pointer;
}

.quoteResult>div.quoteInfoText {
  display: none;
  vertical-align: middle;
  width: 90px;
  padding-right: 10px;
}

.quoteResult>div.quoteInfoText>span {
  cursor: pointer;
}

.quoteResult .quotePrice {
  vertical-align: middle;
  text-align: right;
  padding-right: 20px;
  white-space: nowrap;
}

.quoteResult .quotePrice>.quotePriceEx {
  color: #4579b2;
  font-size: 26px;
  line-height: 1.2;
  font-weight: 600;
}

.quoteResult .quoteBookCell {
  vertical-align: middle;
  width: 92px;
  padding-left: auto;
  padding-right: auto;
}

.quoteResult .quoteBookButton {
  width: 80px;
  background-color: #ff8b03;
  border: 0;
  padding: 4px 16px;
  font-size: 14px;
  border-radius: 3px;
  color: #fff;
  font-weight: 600;
  line-height: 1.4;
  height: 42px;
  vertical-align: middle;
}

.quoteResult .quoteBookButton.disabled {
  padding: 0;
  white-space: normal;
  background-color: #e0e1e1;
  cursor: not-allowed;
  font-size: 13px;
}

.quoteResult .testLogo {
  background-color: #a00;
  font-size: 11px;
  font-weight: 600;
  color: #fff;
  line-height: 1;
  position: absolute;
  top: 3px;
  left: 10px;
  padding: 2px 4px;
  border-radius: 2px;
}

#sidebar {
  border: 2px solid #ebedee;
  width: 100%;
  float: right;
  border-radius: 5px;
}

#sidebar #sidebarLogo {
  width: 140px;
  height: auto;
  display: block;
  margin: 20px auto;
  border-radius: 6px;
}

#sidebar #service {
  color: #4579b2;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  padding: 5px 15px 15px;
}

#sidebar #description {
  font-size: 15px;
  padding: 5px 15px 10px;
  line-height: 1.4;
}

#sidebar #total {
  overflow: hidden;
  padding: 15px;
  background-color: #4579b2;
  border-radius: 0 0 2px 2px;
}

#sidebar #total div {
  color: #fff;
}

.totalBreakdown-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.totalBreakdown-flex span {
  font-size: 30px;
}

.totalBreakdown-hr {
  height: 10px;
  border-bottom: 1px solid white;
}

#cartItems .cartItem td {
  background-color: #ffffff;
  padding-top: 14px;
  padding-bottom: 14px;
}

#cartItems .header td {
  height: auto;
  padding-top: 10px;
  padding-bottom: 10px;
}

.cartItem .quoteLogo {
  text-align: center;
  width: 98px;
}

.cartItem .quoteLogo img {
  width: 100%;
  display: block;
  height: auto;
  border-radius: 2px;
}

.cartItem .quoteCollDropImage {
  width: 30px;
}

.cartItem .quoteCollDropImage img {
  display: block;
  max-width: unset;
}

.cartItem .quoteDelImage {
  width: 30px;
}

.cartItem .quoteDelImage img {
  display: block;
  max-width: unset;
}

.cartItem .quoteCoverImage {
  width: 30px;
}

.cartItem .quoteCoverImage img {
  display: block;
  max-width: unset;
}

.cartItem .quotePrinterImage {
  width: 30px;
}

.cartItem .quotePrinterImage img {
  display: block;
}

.quotePrice {
  text-align: right;
  padding-right: 20px;
  white-space: nowrap;
}

.quotePrice>.quotePriceEx {
  color: #4579b2;
  font-size: 26px;
  line-height: 1.2;
  font-weight: 600;
}

.sucess-card {
  text-align: center;
  padding: 40px 0;
  background: #fff;
}

.sucess-card .card h1 {
  color: #88b04b;
  font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
  font-weight: 900;
  font-size: 40px;
  margin-bottom: 10px;
}

.sucess-card .card p {
  color: #404f5e;
  font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
  font-size: 20px;
  margin: 0;
}

.sucess-card .card i {
  color: #9abc66;
  font-size: 100px;
  line-height: 200px;
  margin-left: -15px;
}

.sucess-card .card {
  background: #ebf0f5;
  padding: 60px;
  border-radius: 4px;
  box-shadow: 0 2px 3px #c8d0d8;
  display: inline-block;
  margin: 0 auto;
}

#cartItems td.cartItemRemove {
  width: 20px;
  text-align: center;
  cursor: pointer;
  font-size: 26px;
  color: #bababa;
}

#cartTotalsDiv {
  display: inline-block;
  margin-top: 14px;
  background-color: #ffffff;
  font-size: 20px;
  color: #4579b2;
  border-radius: 2px;
  padding: 16px 20px;
  border: 1px solid #e9eaea;
}

#cartTotals tr td {
  padding: 1px 0;
}

#cartTotals tr td:nth-child(2) {
  text-align: right;
  padding-left: 30px;
}

#totalRow>td {
  font-weight: 600;
}

table.table {
  width: 100%;
  font-size: 12px;
  line-height: 1.6;
  background-color: #fff;
  border: 1px solid #e9eaea;
}

table.table td {
  padding: 5px 10px;
  vertical-align: middle;
  height: 42px;
  color: #585858;
}

table.table.hover tr:not(:first-child):not(.noHover):not(.disabled):hover {
  cursor: pointer;
}

table.table tr.disabled td {
  color: #bababa;
  cursor: default;
  pointer-events: none;
}

table.table:not(.noHeader) tr:first-child td {
  border-bottom: 1px solid #eaebec;
}

table.table {
  border-collapse: separate;
  border-radius: 3px;
}

table.table tr:first-child td:first-child {
  border-top-left-radius: 2px;
}

table.table tr:first-child td:last-child {
  border-top-right-radius: 2px;
}

table.table tr:last-child td:first-child {
  border-bottom-left-radius: 2px;
}

table.table tr:last-child td:last-child {
  border-bottom-right-radius: 2px;
}

table.table:not(.noHeader) tr:first-child td {
  background-color: #f8f9fa;
  font-size: 12px;
  font-weight: 600;
}

table.table tr:nth-child(odd) {
  background-color: #fbfcfd;
}

table.table tr:not(:last-child) td {
  border-bottom: 1px solid #f4f5f6;
}

table.table.hover tr:not(:first-child):not(.noHover):not(.disabled):hover td {
  background-color: #f8f9fa;
}

@media only screen and (max-width: 1180px) {
  .quoteResult {
    height: auto;
    padding: 12px 16px 16px;
  }

  .quoteResult>div {
    display: inline-block;
  }

  .quoteResult>div.quoteBreak2 {
    display: block;
    width: 100%;
    height: 8px;
  }

  .quoteResult>div.quoteInfoText {
    display: inline-block;
  }

  .quoteResult>div.quoteLogo {
    padding-left: 0;
  }

  .quoteResult>div.quoteService {
    font-size: 16px;
    width: auto;
  }

  .quoteResult>div.quoteService br {
    display: none;
  }

  .quoteResult>div.quoteService:after {
    white-space: pre;
    content: "\A";
  }

  .quoteResult>div.quotePrice {
    float: right;
    padding-right: 0;
  }

  .quoteResult>div.quotePrice>.quotePriceEx {
    font-size: 30px;
    line-height: 1;
  }

  .quoteResult>div.quoteBookCell {
    float: right;
    text-align: right;
    vertical-align: bottom;
  }

  .quoteResult>div.quoteBookCell>input {
    padding-left: 24px;
    padding-right: 24px;
  }
}

@media only screen and (max-width: 925px) {
  .quoteResult>div.quoteInfoText {
    display: none;
  }
}

@media only screen and (max-width: 830px) {
  .quoteResult {
    margin-bottom: 12px;
    padding: 12px 20px 16px;
  }

  .quoteResult>div.quoteBreak1 {
    display: block;
    width: 100%;
    height: 12px;
  }

  .quoteResult>div.quoteBreak2 {
    height: 6px;
  }

  .quoteResult>div.quoteInfoText {
    display: inline-block;
  }

  .quoteResult>div.quotePrice>.quotePriceEx {
    font-size: 38px;
    font-weight: 400;
    padding-top: 4px;
  }
}

@media only screen and (max-width: 660px) {
  .quotePanel {
    padding: 20px 10px;
  }

  .quoteResult>div.quoteService {
    font-size: 15px;
  }

  .quoteResult>div.quoteService br {
    display: inline;
  }

  .quoteResult>div.quoteService:after {
    content: none;
  }

  .quoteResult>div.quoteInfoText {
    display: none;
  }

  .quoteResult>div.quotePrice>.quotePriceEx {
    font-size: 34px;
    padding-top: 0;
  }
}

@media only screen and (max-width: 520px) {
  .quoteResult {
    padding-bottom: 8px;
    padding-top: 8px;
  }

  .quoteResult>div.quoteBreak2 {
    height: 12px;
  }

  .quoteResult>div.quoteBreak3 {
    display: block;
    width: 100%;
    height: 16px;
  }

  .quoteResult>div.quotePrice {
    float: none;
  }

  .quoteResult>div.quotePrice {
    text-align: left;
  }

  .quoteResult>div.quoteInfoImage {
    float: right;
    padding-top: 12px;
  }

  .quoteResult>div.quoteBookCell {
    padding-top: 4px;
  }
}

@media only screen and (max-width: 520px) {
  #qtbCarrier {
    display: none;
  }

  .quoteResult>div.quoteBreak1 {
    display: none;
  }

  .quoteResult>div.quoteCollDropImage {
    padding: 6px 0;
  }

  .quoteResult>div.quoteCollDropText {
    display: inline;
    width: auto;
  }

  .quoteResult>div.quoteCollDropText br {
    display: none;
  }

  .quoteResult>div.quoteCollDropText:after {
    white-space: pre;
    content: "\A";
  }

  .quoteResult>div.quoteDelImage {
    padding: 6px 0;
  }

  .quoteResult>div.quoteDelText {
    display: inline;
    width: auto;
  }

  .quoteResult>div.quoteDelText br {
    display: none;
  }

  .quoteResult>div.quoteDelText:after {
    white-space: pre;
    content: "\A";
  }

  .quoteResult>div.quoteCoverImage {
    padding: 6px 0;
  }

  .quoteResult>div.quoteCoverText {
    display: inline;
    width: auto;
  }

  .quoteResult>div.quoteCoverText br {
    display: none;
  }

  .quoteResult>div.quoteCoverText:after {
    white-space: pre;
    content: "\A";
  }

  .quoteResult>div.quotePrinterImage {
    padding: 6px 0;
  }

  .quoteResult>div.quotePrinterText {
    display: inline;
    width: auto;
  }

  .quoteResult>div.quotePrinterText br {
    display: none;
  }
}

@media only screen and (max-width: 390px) {
  .quoteResult {
    padding-left: 10px;
    padding-right: 10px;
  }

  .quoteResult>div.quoteService {
    font-size: 15px;
  }

  .quoteResult>div.quoteCollDropImage {
    padding: 3px 0;
  }

  .quoteResult>div.quoteDelImage {
    padding: 3px 0;
  }

  .quoteResult>div.quoteCoverImage {
    padding: 3px 0;
  }

  .quoteResult>div.quotePrinterImage {
    padding: 3px 0;
  }

  .quoteResult>div img {
    height: 20px;
  }
}



/* this is my new css  */
.divider {
  display: flex;
  align-items: center;
  gap: 20px;
}

.divider div {
  width: 20px;
  flex-grow: 1;
  height: 2px;
  background-color: #585858;
}

.divider p {
  color: #585858;
}

.subTable th {
  background-color: white;
  border-bottom: 1px solid;
}

.extraLink {
  color: white;
  -webkit-text-decoration: none;
  text-decoration: none;
  display: block;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  font-family: Poppins;
  background-color: transparent;
  text-align: center;
  width: 100%;
  border: none;
  outline: none;
}